<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-upload"></i>
          استيراد الطلاب
        </h4>
      </div>
      <div class="card-body">
        <input type="file" name="" id="ingredient_file" />
        <div id="progress-wrp">
          <div class="progress-bar"></div>
          <div class="status">0%</div>
        </div>
      </div>
      <div class="card-footer" v-if="students.length">
        <div class="form-group">
          <h5 for="">القسم</h5>
          <select class="form-control" name="" v-model="section_id" id="">
            <template v-for="section in sections">
              <option :value="section._id" :key="section._id">
                {{ section.title }}
              </option>
            </template>
          </select>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" ref="addbtn" @click="add()">
            <i class="fa fa-user-check"></i>
            اضافة الطلاب للنظام ({{ students.length }})
          </button>
        </div>
        <div class="col-12 g table-responsive">
          <table class="table table-hover">
            <thead>
              <th>الإسم</th>
              <th>رقم الهوية</th>
              <th>الهاتف</th>
              <th>الصف</th>
              <th>الفصل</th>
            </thead>
            <tbody>
              <tr v-for="student in students" :key="student[5]">
                <td>
                  {{ student.name }}
                </td>
                <td>
                  {{ student.number }}
                </td>
                <td>
                  {{ student.phone }}
                </td>
                <td>
                  {{ student.classname }}
                </td>
                <td>
                  {{ student.classroom }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      students: [],
      user: JSON.parse(localStorage.getItem("user")),
      sections: [],
      section_id: null,
    };
  },
  created() {
    checkPer(this, "students", "add");
    var g = this;
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("upload_file", true);

      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_BRMJA + "/excel",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          // your callback here
          g.students = [];
          $("#progress-wrp").fadeOut("slow");
          data.forEach(function (student) {
            if (student[5]) {
              student[5] = student[5].toString();
              if (
                parseInt(student[5]) != "NanN" &&
                student[5].trim() != "" &&
                !student[5].includes("ا")
              ) {
                g.students.push({
                  name: student[4],
                  phone: student[1],
                  classname: student[3],
                  classroom: student[2],
                  number: student[5],
                });
              }
            }
          });
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
      $.post(api + "/admin/sections/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.sections = JSON.parse(r).response;
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
  },
  methods: {
    add() {
      var g = this;
      g.$refs.addbtn.innerHTML = `<div class="spinner-border text-white" role="status"></div>`;
      $.post(api + "/admin/students/import", {
        jwt: g.user.jwt,
        arr: JSON.stringify(g.students),
        section_id: g.section_id,
      })
        .then(function () {
          alert("تم استيراد الطلاب بنجاح");
          location.reload();
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
        });
    },
  },
};
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>